/*================================
	media
=================================*/
$break-point: 769px;

@mixin pc {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: #{$break-point - 1}) {
    @content;
  }
}


/*================================
	fonts
=================================*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500&display=swap');

$font-family: 'Noto Sans JP', sans-serif;

$alphanumeric: 'Ubuntu', sans-serif;

/*================================
	共通設定
=================================*/

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: $font-family;
  font-weight: 400;
  font-feature-settings : "palt"; 
  letter-spacing : 0.05em;
  color: #222222;
  height: -webkit-fill-available;
  line-height: 1;
}

body{
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1.4;
}

p {
  line-height: 1.4;
}

ul {
  list-style: none;
  margin-left: 0;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.pc-only {
  display: block !important;
  @include sp() {
    display: none !important;
  }
}

.sp-only {
  display: block !important;
  @include pc() {
    display: none !important;
  }
}


/*================================
	Site color
=================================*/
$main-color: #000066;
$sub-color: #1193dd;