@charset "UTF-8";

@import 'src/common/css/global/_base';

/*================================
	top
=================================*/
.main {
	position: relative;
	@include pc() {
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: url(/img/img_main_video_mask.png) no-repeat top center;
			background-size: cover;
			z-index: 1;
		}
	}

	figure {
		overflow: hidden;
	}
	video {
		width: 100%;

		.マスク {
			position: absolute;
			left: 0px;
			top: 0px;
			width: 1366px;
			height: 680px;
			z-index: 14;
		}
		
	}

	&_inner {
		margin: 0 auto;
		position: absolute;
		left: 0;
		
		@include pc() {
			width: 100%;
			padding: 0 50px;
			bottom: 56px;
		}

		@include sp() {
			bottom: 175px;
			margin-right: 40px;
		}

		&_company {
			color: #fff;
			font-family: $alphanumeric;
			font-weight: 300;

			@include pc() {
				font-size: 2rem;
			}

			@include sp() {
				font-size: 1.4em;
				margin-left: 15px;
			}
		}

		&_catch_02 {
			display: block;
			
			@include pc() {
				width: 32.75vw;
				min-width: 460px;
				margin-top: 8px;
			}

			@include sp() {
				width: 100%;
				margin-top: 5px;
			}
		}

	}

	&_news {
		background: #fff;
		
		@include pc() {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 543px;
			height: 114px;
			z-index: 2;
		}
	}

	&_newsWrap {
		height: 100%;
		border-bottom: 1px solid #ccd2d6;
		
		@include pc() {
			display: flex;
		}
	}
	&_newsList {
		@include pc() {
			width: 144px;
		}

		@include sp() {
			width: 100%;
			height: 48px;
		}
		
		a {
			width: 100%;
			height: 100%;
			padding: 0 30px;
			background-color: #f3f2f8;
			display: flex;
			position: relative;
			@include pc() {
				flex-direction: column;
				justify-content: center;
			}

			@include sp() {
				padding: 0 15px;
				justify-content: space-between;
				align-items: center;
			}

			&:hover {
				.icon-arrow {
					&::after {
						animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
					}
				}
			}

			p {
				color: $main-color;
				line-height: 1;

				&:first-child {
					font-family: $alphanumeric;
					font-weight: 300;
					font-size: 3rem;

					@include sp() {
						font-size: 2.4rem;
					}
				}
				&:last-child {
					@include pc() {
						margin-top: 15px;
						font-size: 1.6rem;
					}
					
					@include sp() {
						font-size: 1.5rem;
					}
				}

			}
		}
	}
	&_newsPickup {
		padding: 20px;
		display: block;

		&.news_list_item:last-child {
			padding-bottom: 20px;

		}
		@include sp() {
			padding: 15px;
		}
		
		a {
			@include pc() {
				margin: 0;
				padding: 0;
			}
		}

		p {
			&:last-child {
				margin-top: 12px;
				display: flex;
				align-items: center;

				@include sp() {
					margin-top: 5px;
				}
			}
		}

		&_date {
			color: #7a7a7a;
			font-size: 1.5rem;

			@include sp() {
				font-size: 1.4rem;
			}
		}
	
		.-news_label {
			margin-left: 10px;
		}
	}
}

/*================================
	top section common
=================================*/
.sec {
	&_head {
		height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include sp() {
			height: auto;
			flex-wrap: wrap;
		}

		&.-color_white {
			.sec_ttl {
				color: #fff;
			}
			.sec_link {
				a {
					color: #fff;

					&::after {
						background: url(/common/img/icon_arrow_right_white.png) no-repeat center;
						background-size: 16px 9px;
					}

					.circle_animation {
						.outer {
							stroke: #fff;
						}
					}
				}
			}
		}
	}
	&_ttl {
		font-size: 7.2rem;
		font-family: $alphanumeric;
		font-weight: 300;
		color: $main-color;

		@include sp() {
			font-size: 4.8rem;
		}
	}
	&_catch {
		@include pc() {
			font-size: 1.6rem;
			padding-left: 60px;
			margin-right: auto;
			margin-top: 33px;
			position: relative;

			&::before {
				content: '';
				width: 36px;
				height: 36px;
				background: url(/img/img_sec_catch_line.png) no-repeat center;
				background-size: contain;
				position: absolute;
				left: 18px;
				bottom: 2px;
			}
		}

		@include sp() {
			font-size: 1.5rem;
			order: 3;
			width: 100%;
			margin-top: 20px;
		}
	}
	&_link {
		height: 100px;

		@include sp() {
			height: 60px;
			// margin-top: 18px;
		}

		a {
			height: 100%;
			padding-right: 130px;
			font-size: 2.4rem;
			color: $main-color;
			display: flex;
			align-items: center;
			position: relative;
			transition: all .2s ease;

			@include sp() {
				font-size: 2rem;
				padding-right: 80px;
			}

			&::after {
				content: '';
				width: 100px;
				height: 100px;
				background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
				background-size: 16px 9px;
				position: absolute;
				top: 0;
				right: 0;

				@include sp() {
					width: 60px;
					height: 60px;
				}
			}

			&:hover {
				color: $main-color;
				
				.circle_animation {
					.outer {
						stroke: $main-color;
						stroke-width: 1;
						stroke-dasharray: 600;
						stroke-dashoffset: 0;
					}
				}

			}

			span {
				position: absolute;
				top: 0;
				right: 0;

				.circle_animation {
					height: 100px;
					width: 100px;
					@include sp() {
						width: 60px;
						height: 60px;
					}

					.outer {
						stroke: #006;
						stroke-width: 1;
						fill: transparent;
						stroke-dashoffset: 600;
						transition: stroke-dashoffset 1.5s;
					}
				}
			}
		}
	}
	&_imgList_2col {
		
		@include pc() {
			margin-top: 58px;
			display: flex;
			justify-content: space-between;
		}

		@include sp() {
			margin-top: 30px;
		}

		li {
			@include pc() {
				width: calc(50% - 10px);
			}
			@include sp() {
				margin-top: 16px;
			}

			a {
				width: 100%;
				height: 100%;
				display: block;
				position: relative;

				p {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					color: #fff;
					font-size: 2.4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
				}
				
			}
		}
	}
}

/*================================
	service section
=================================*/
.service {
	&_inner {
		@include pc() {
			padding: 100px 0 70px;
			width: calc(100% - 50px);
			min-width: 1260px;
			margin-left: auto;
			overflow: hidden;
		}
		@include sp() {
			padding: 60px 0;
		}

		.sec_head {
			@include pc() {
				padding-right: 50px;
			}
			@include sp() {
				padding: 0 15px;
			}
		}
	}
	&_list {
		@include pc() {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 45px;
		}
		@include sp() {
			margin-top: 30px;
		}

		&_item {
			@include pc() {
				width: calc(50% - 8px);

				&:nth-child(n + 3) {
					margin-top: 70px;
					padding-bottom: 43px;
				}

				&:first-child,
				&:nth-child(2) {
					.service_list_bgTxt {
						bottom: -15px;
					}
				}
				&:last-child,
				&:nth-child(3) {
					.service_list_bgTxt {
						bottom: -5px;
					}
				}
			}
			@include sp() {
				padding-bottom: 11px;
				&:not(:first-child) {
					margin-top: 50px;
				}
			}

			a {
				position: relative;

				&:hover {
					img {
						transform: scale(1.1);
					}
					.icon-arrow {
						&::after {
							animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
						}
					}
				}
			}
		}
		&_ttl {
			margin-top: 25px;
			font-size: 2.6rem;
			position: relative;
			display: inline-block;
			color: $main-color;

			@include sp() {
				font-size: 2.4rem;
				padding: 0 20px;
			}

			.icon-arrow {
				top: -7px;
			}
		}
		&_txt {
			margin-top: 15px;
			font-size: 1.6rem;
			line-height: 1.6;
			padding-right: 100px;

			@include pc() {
				height: 77px;
			}
			@include sp() {
				padding: 0 20px;
				margin-top: 10px;
				font-size: 1.5rem;
			}
		}
		&_bgTxt {
			font-family: $alphanumeric;
			font-weight: 300;
			color: #f3f2f8;
			font-size: 7.2rem;
			position: absolute;
			right: 0;
			z-index: -1;
			line-height: 1;

			@include sp() {
				font-size: 3.6rem;
				bottom: -12px;
			}

			span {
				font-size: 4.8rem;
				@include sp() {
					font-size: 3rem;
				}
			}
		}
	}
}

/*================================
	network section
=================================*/
.network {
	background-color: #f3f2f8;
	@include pc() {
		padding: 60px 0;
	}
	@include sp() {
	}

	&_inner {
		@include pc() {
			width: 1200px;
			margin: 0 auto;
			position: relative;
		}
		@include sp() {
			padding: 60px 0;
		}

		.sec_head {
			@include pc() {
				position: absolute;
				top: 60px;
				z-index: 2;
			}
			@include sp() {
				padding: 0 15px;
			}
		}
	}
	&_list {
		@include pc() {
			top: 300px;
			right: 50px;
		}
		@include sp() {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 15px;
			padding: 0 15px;
		}
		
		li {
			color: $main-color;
			border-bottom: 1px solid #ccd2d6;
			line-height: 1.4;
			
			@include pc() {
				width: 180px;
				margin-top: 18px;
				font-size: 1.6rem;
				cursor: pointer;
				position: absolute;

				&.-branch_tokyo{
					right: 0;
					top: 522px;
				}
				&.-branch_kawasaki{
					right: 0;
					top: 581px;
				}
				&.-branch_higashiogishima{
					right: 0;
					top: 645px;
				}
				&.-branch_osaka{
					left: 469px;
					top: 245px;
				}
				&.-branch_hakata{
					left: 469px;
					top: 304px;
				}
				&.-branch_kashima{
					right: 0;
					top: 412px;
				}
				&.-branch_shibushi{
					left: 469px;
					top: 363px;
				}
				&.-branch_oi{
					right: 0;
					top: 704px;
				}
				&.-branch_hitachinaka{
					right: 0;
					top: 353px;
				}

				&:hover {		
					&::before {
						transform-origin: left top;
						transform: scale(1, 1);
					}
				}
			}
			@include sp() {
				width: calc(50% - 13px);
				padding: 15px 0;
				font-size: 1.5rem;
				position: relative;
			}

			&::before {
				content: '';
				width: 100%;
				height: 1px;
				background-color: $main-color;
				transition: transform .3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
				transform: scale(0, 1);
				transform-origin: right top;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			&::after {
				content: '';
				position: absolute;
				width: 16px;
				height: 9px;
				background: url(/common/img/icon_arrow_right_blue.png) no-repeat center;
				background-size: contain;
				top: calc(50% - 3px);
				right: 0;
			}

			a {
				display: block;
				@include pc() {
					padding: 0 0 18px 0;
				}
			}
		}
	}

	&_link_wrap {
		display: flex;
		
		@include pc() {
			flex-direction: column;
			position: absolute;
			top: 186px;
			left: 0;
		}
		@include sp() {
			margin-top: 40px;
			padding: 0 15px;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		p {
			@include pc() {
				font-size: 2rem;
			}
			@include sp() {
				width: 100%;
				font-size: 1.8rem;
			}
		}
	}
	&_link_btn {
		margin-top: 20px;
		@include pc() {
			height: 85px;
		}
		@include sp() {
			width: calc(50% - 8px);
			height: 100px;

			&::after {
				right: calc(50% - 8px);
				top: inherit;
				bottom: 29px;
			}
		}

		& + .network_link_btn {
			@include pc() {
				margin-top: 10px;
			}
		}

		a {
			@include sp() {
				justify-content: center;
				align-items: inherit;
				padding-top: 30px;
			}
		}
	}

	&_map {
		position: relative;
		@include sp() {
			margin: 0 15px;
		}

		span {
			position: absolute;
			opacity: 0;
			transition: all .4s ease;
			background: url(/common/img/icon_map_pin_02.png) no-repeat center;
			background-size: contain;
			
			&.js_pin {
				z-index: 2;
				transform: translateY(100%);
				width: 17px;
				height: 31px;

				&.is-active {
					opacity: 1;
					transform: translateY(0);
				}
			}
			&.pin_zoomBg {
				width: 484px;
				height: 484px;
				transform: scale(0.5);
				z-index: 1;

				&.is-active {
					opacity: 1;
					transform: scale(1);
				}
			}

		}

		.pin_tokyo_01 {
			bottom: 395px;
			right: 493px;
		}
		.pin_tokyo_02 {
			bottom: 248px;
			right: 350px;
		}
		.pin_tokyo_03 {
			bottom: 244px;
			right: 360px;
		}
		.pin_tokyo_04 {
			bottom: 244px;
			right: 416px;
		}
		.pin_tokyo_05 {
			bottom: 205px;
			right: 346px;
		}
		.pin_tokyo_06 {
			bottom: 203px;
			right: 351px;
		}
		.pin_tokyo_07 {
			bottom: 198px;
			right: 361px;
		}
		.pin_tokyo_08 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 197px;
			right: 356px;
		}
		.pinBg_tokyo {
			background: url(/common/img/img_circle_tokyo.png) no-repeat center;
			background-size: contain;
			bottom: 48px;
			right: 214px;
		}

		.pin_kawasaki_01 {
			bottom: 293px;
			right: 413px;
		}
		.pin_kawasaki_02 {
			bottom: 270px;
			right: 444px;
		}
		.pin_kawasaki_03 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 275px;
			right: 434px;
		}
		.pinBg_kawasaki {
			background: url(/common/img/img_circle_kawasaki.png) no-repeat center;
			background-size: contain;
			bottom: 48px;
			right: 214px;
		}

		.pin_higashiogishima_01 {
			bottom: 296px;
			right: 403px;
		}
		.pin_higashiogishima_02 {
			bottom: 248px;
			right: 473px;
		}
		.pin_higashiogishima_03 {
			bottom: 248px;
			right: 468px;
		}
		.pin_higashiogishima_04 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 256px;
			right: 460px;
		}
		.pinBg_higashiogishima {
			background: url(/common/img/img_circle_higashiogishima.png) no-repeat center;
			background-size: contain;
			bottom: 17px;
			right: 214px;
		}
		
		.pin_osaka_01 {
			bottom: 239px;
			right: 659px;
		}
		.pin_osaka_02 {
			bottom: 238px;
			right: 646px;
		}
		.pin_osaka_03 {
			bottom: 220px;
			right: 325px;
		}
		.pin_osaka_04 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 235px;
			right: 659px;
		}
		.pinBg_osaka {
			background: url(/common/img/img_circle_osaka.png) no-repeat center;
			background-size: contain;
			bottom: -7px;
			right: 260px;
		}
		
		.pin_hakata_01 {
			bottom: 208px;
			right: 891px;
		}
		.pin_hakata_02 {
			bottom: 264px;
			right: 936px;
		}
		.pin_hakata_03 {
			bottom: 264px;
			right: 932px;
		}
		.pin_hakata_04 {
			bottom: 267px;
			right: 920px;
		}
		.pin_hakata_05 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 266px;
			right: 925px;
		}
		.pinBg_hakata {
			background: url(/common/img/img_circle_hakata.png) no-repeat center;
			background-size: contain;
			bottom: 14px;
			right: 679px;
		}
		
		.pin_kashima_01 {
			bottom: 216px;
			right: 349px;
		}
		.pin_kashima_02 {
			bottom: 215px;
			right: 346px;
		}
		.pin_kashima_03 {
			bottom: 209px;
			right: 344px;
		}
		.pin_kashima_04 {
			bottom: 211px;
			right: 343px;
		}
		.pin_kashima_05 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 213px;
			right: 347px;
		}
		.pinBg_kashima {
			background: url(/common/img/img_circle_kashima.png) no-repeat center;
			background-size: contain;
			bottom: 14px;
			right: 216px;
		}
		
		.pin_shibushi_01 {
			bottom: 216px;
			right: 755px;
		}
		.pin_shibushi_02 {
			bottom: 138px;
			right: 969px;
		}
		.pin_shibushi_03 {
			bottom: 99px;
			right: 862px;
		}
		.pin_shibushi_04 {
			bottom: 98px;
			right: 860px;
		}
		.pin_shibushi_05 {
			bottom: 97px;
			right: 855px;
		}
		.pin_shibushi_06 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 99px;
			right: 858px;
		}
		.pinBg_shibushi {
			background: url(/common/img/img_circle_shibushi.png) no-repeat center;
			background-size: contain;
			bottom: -21px;
			right: 666px;
		}
		
		.pin_oi_01 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 264px;
			right: 429px;
		}
		.pinBg_oi {
			background: url(/common/img/img_circle_oi.png) no-repeat center;
			background-size: contain;
			bottom: 14px;
			right: 215px;
		}
		
		.pin_hitachinaka_01 {
			background: url(/common/img/icon_map_pin_01.png) no-repeat center;
			background-size: contain;
			bottom: 262px;
			right: 431px;
		}
		.pinBg_hitachinaka {
			background: url(/common/img/img_circle_hitachinaka.png) no-repeat center;
			background-size: contain;
			bottom: 14px;
			right: 215px;
		}
	}
}

/*================================
	history section
=================================*/
.history {
	@include pc() {
		background: url(/img/bg_history_pc.jpg) no-repeat center;
		background-size: cover;
	}
	@include sp() {
		background: url(/img/bg_history_sp.jpg) no-repeat center;
		background-size: cover;
	}
	&_inner {
		margin: 0 auto;
		@include pc() {
			width: 1200px;
			padding: 140px 0;
		}
		@include sp() {
			padding: 80px 15px;
		}

		.sec_head {
			flex-wrap: wrap;
			height: auto;
			position: relative;
		}

		.sec_link {
			@include pc() {
				position: absolute;
				right: 0;
				bottom: 0;
			}
			@include sp() {
				margin-top: 60px;
				margin-left: auto;
			}
		}

		p {
			width: 100%;
			color: #fff;
			line-height: 2.4;

			@include pc() {
				margin-top: 60px;
				font-size: 1.8rem;
			}
			@include sp() {
				margin-top: 40px;
				font-size: 1.6rem;
			}
		}
	}
}

/*================================
	ir section
=================================*/
.ir {
	width: 100%;
	background-image: url(/img/bg_ir.jpg);
	background-size: cover;
	background-position: top 400px center;
	background-repeat: no-repeat;
	
	@include sp() {
		background-position: top 455px center;
	}

	&_inner {
		margin: 0 auto;
		@include pc() {
			width: 1200px;
			padding: 100px 0;
		}

		@include sp() {
			padding: 60px 15px;
		}
	}
	&_iconLink {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		@include sp() {
			flex-wrap: wrap;
		}

		li {
			@include pc() {
				width: calc(25% - 15px);
			}
			@include sp() {
				width: calc(50% - 8px);

				&:nth-child(n + 3){
					margin-top: 15px;
				}
			}

			a {
				width: 100%;
				height: 100%;
				padding: 60px 0;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@include sp() {
					padding: 29px 0 20px;
				}
				
				img {
					width: 64px;

					@include sp() {
						width: 51px;
					}
				}
				p {
					font-size: 2rem;
					color: $main-color;
					margin-top: 30px;
					@include sp() {
						font-size: 1.6rem;
						margin-top: 13px;
					}
				}

			}
		}
	}

	&_news {
		@include pc() {
			margin-top: 70px;
			display: flex;
			justify-content: space-between;
		}
		@include sp() {
			margin-top: 50px;
		}

		&_head {
			@include sp() {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			h3 {
				font-family: $alphanumeric;
				font-weight: 300;
				color: $main-color;
				font-size: 4.8rem;
			}
		}
		&_btn {
			@include pc() {
				margin-top: 50px;
				width: 227px;
				height: 60px;
			}

			@include sp() {
				width: 150px;
				height: 40px;
			}
		}

		&_list {
			width: 100%;
			@include pc() {
				margin-left: 70px;
			}
			@include sp() {
				margin-top: 40px;
			}

			li {
				display: flex;
				align-items: center;
				@include pc() {
					padding: 30px 0;	
				}
				
				@include sp() {
					padding: 15px 0;	
					flex-wrap: wrap;
				}

				&:first-child {
					padding-top: 15px;
				}
				&:last-child {
					padding-bottom: 0;
				}

				a {
					@include pc() {
						flex: 1;
						margin-left: 30px;
					}
					@include sp() {
						width: 100%;
						margin-top: 15px;
					}
				}
			}
		}
	}
}

/*================================
	sustainability section
=================================*/
.sustain {
	width: 100%;
	background-image: url(/img/bg_sustainability.jpg);
	background-size: cover;
	background-position: bottom 85px center;
	background-repeat: no-repeat;
	@include sp() {
		background-position: bottom 62px center;
	}

	&_inner {
		margin: 0 auto;
		@include pc() {
			width: 1200px;
			padding-top: 130px;
		}

		@include sp() {
			padding: 80px 15px 0;

			.sec_head {
				flex-direction: column;
				height: 123px;
			}
			.sec_link {
				margin-top: 15px;

				a{
					padding-right: 80px;
				}
			}
		}
	}
	&_list {
		@include sp() {
			margin-top: 35px;
		}
	}
	&_iconLink {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		@include sp() {
			flex-wrap: wrap;
			margin-top: 25px;
		}

		li {
			&:not(:first-child) {
				border: 1px solid #ccd2d6;
			}
			@include pc() {
				width: 334px;

				&:first-child {
					width: 138px;
					background-color: #f3f2f8;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					p {
						font-size: 1.8rem;
					}
				}
			}
			@include sp() {
				width: calc(33% - 3px);

				&:first-child{
					width: 100%;
					padding: 13px 0;
					background-color: #f3f2f8;
					
					p {
						font-size: 1.7rem;
						text-align: center;
					}
				}
				&:not(:first-child){
					margin-top: 6px;
					a {
						flex-direction: column;
						p {
							margin-left: 0;
						}
					}
				}
			}

			a {
				width: 100%;
				height: 100%;
				padding: 55px 0;
				background-color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;

				@include sp() {
					padding: 20px 0;
				}
				
				img {
					width: 80px;

					@include sp() {
						width: 50px;
					}
				}

			}
		}
	}
	&_txtEn {
		color: $main-color;
		@include pc() {
			font-size: 1.6rem;
			margin-left: 30px;
		}
		@include sp() {
			margin-top: 5px;
			font-size: 1.3rem;
		}
	}
	&_txtJp {
		margin-left: 0;
		margin-top: 4px;
		color: $main-color;
		@include pc() {
			font-size: 1.8rem;
			margin-left: 30px;
		}
		@include sp() {
			margin-top: 3px;
			text-align: center;
			font-size: 1.4rem;
		}
	}
}

/*================================
	recruit section
=================================*/
.recruit {
	padding-top: 100px;
	@include sp() {
		padding-top: 60px;
	}
	
	&_inner {
		margin: 0 auto;
		position: relative;
		
		@include pc() {
			width: 1200px;
		}

		.sec_head {
			position: absolute;
			
			@include pc() {
				right: 70px;
				top: calc(50% - 50px);
			}
			@include sp() {
				right: 10px;
				bottom: 40px;
			}
		}

		figure {
			width: 100%;
			@include pc() {
				height: 400px;
				background: url(/img/img_recruit_pc.jpg) no-repeat center;
				background-size: 100%;
			}
			@include sp() {
				height: 510px;
				background: url(/img/img_recruit_sp.jpg) no-repeat top left;
				background-size: cover;
			}
		}
		img {
			width: 100%;
		}
	}
}

/*================================
	top news section
=================================*/
.topNews {
	@include pc() {
		padding: 30px 0 100px;
	}

	@include sp() {
		padding: 10px 15px 100px;
	}

	&_inner {
		margin: 0 auto;
		@include pc() {
			width: 1200px;
		}
	}
}